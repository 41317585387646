import { gql } from '@apollo/client';

const chatMessageFragment = gql`
  fragment MessageFragment on Message {
    __typename
    id
    hideForExpert
    hideForUser
    createdAt
    type
    author {
      __typename
      ... on BotAuthor {
        id
        name
        photoUrl
      }
      ... on UserAuthor {
        id
        name
        phone
        photoUrl
      }
      ... on ExpertAuthor {
        id
        name
        photoUrl
      }
    }
    ... on ContactMessage {
      user {
        id
        name
        phone
        email
      }
      text
      metadata
    }
    ... on AttachmentMessage {
      attachment {
        id
        filename
        mimetype
        url
      }
    }
    ... on LinkMessage {
      link {
        id
        title
        description
        url
        image
        imageWidth
        imageHeight
      }
    }
    ... on SmsFallbackMessage {
      text
      metadata
      phone
    }
    ... on WaitFallbackMessage {
      text
      metadata
      email
    }
    ... on TextMessage {
      text
      metadata
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export default chatMessageFragment;
